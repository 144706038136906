import React from 'react';
import styled from 'styled-components';
import './App.css';
import { LtvCalculatorComponent } from './../components/LtvCalculator/LtvCalculatorComponent';
import {HomeHeader} from "../components/Layout";

const getParameterByName = (name: string, url = window.location.href): any =>{
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const Hdr = styled.div`
  width: 100%;
  text-align: center;
  background: #fff;
  color: #000;
  padding: 20px 0px;
  max-width: 800px;
  margin: 20px auto 70px auto;

  h1 {
    font-weight: 700;
    text-align: right;
    color: #3D3950;
    margin-top: 15px;
    padding: 0;
    float: right;
  }
`;

const Header = () => (
  <Hdr>
    <img style={{float: 'left', height: 60}} src={'images/logo.png'} />
    <h1>Loan-to-value calculator</h1>
    {/*<div className="intro">*/}
    {/*  Enter basic information below to find out how much cash*/}
    {/*  you could release from your home with a Lifetime Mortgage.*/}
    {/*  No contact details are required.*/}
    {/*</div>*/}
  </Hdr>
);

const App = () => {
  const inFrame = !getParameterByName('iframe');
  return (
    <div className='app'>

      {inFrame ? <Header /> : null}
      <LtvCalculatorComponent />
    </div>
  );
}

export default App;
