import React, { useState } from 'react';
import {Centered, CalculatorSection, Header, Footer, Disclaimer, HomeHeader} from './../Layout';
import { TextInput, RangerInput } from '../Form/index';
import { LevelsOfEnhancement } from './LevelsOfEnhancement';
import { getLtvValue } from './../../services/LtvCalc';
import { ILtvCalcInput } from './../../services/types';

export const defaultInput: ILtvCalcInput = {
  clientAge: 55,
  housePrice: 400000,
  isJoint: false,
  date: "",
  maxHousePrice: 1000000
};

let resetRun = false;

const ClientDetails = (props: any) => {
  const input: ILtvCalcInput = props.input;
  const setInput: Function = props.setInput;

  if(!resetRun)
  {
    setTimeout(function() { setInput({clientAge: 55, housePrice: 400000, isJoint: false, maxHousePrice: 1000000})}, 100);
    setTimeout(function() { setInput({clientAge: 55, housePrice: 400000, isJoint: false, maxHousePrice: 1000000})}, 500);
    setTimeout(function() { setInput({clientAge: 55, housePrice: 400000, isJoint: false, maxHousePrice: 1000000})}, 999);

    resetRun = true;
  }

  return  (
    <CalculatorSection>
      <div className="title">Client Details</div>
      <div className="section-row">
        <div className="column">
          <div className="section-row" style={{paddingBottom: '8px', boxShadow: 'none'}}>
            <input checked={input.isJoint === false} onChange={(val) => { setInput({ ...input, isJoint: Boolean(parseInt(val.target.value)) }) }} type="radio" value="0" name="isJoint" /> Single
            <input checked={input.isJoint === true} onChange={(val) => { setInput({ ...input, isJoint: Boolean(parseInt(val.target.value)) }) }} type="radio" value="1" name="isJoint" /> Joint
          </div>
          <div className="label">Age (youngest if couple)</div>
          <div style={{ paddingTop: 10 }}>
            <RangerInput
              value={input.clientAge} min={55} max={90} withLabel={true}
              color="#05AABC"
              fontColor="#fff"
              inactiveColor='#bbbbbb'
              onChange={(val: number) => { setInput({ ...input, clientAge: val }) }}
            />
          </div>
        </div>
        <div className="small-column">
          <div className="label">Value of Home</div>
          <div>
            <TextInput
              width={150}
              type="currency"
              prefixes="&pound;"
              value={input.housePrice}
              onChange={(val: number) => { setInput({ ...input, housePrice: val }) }}
            />
          </div>
        </div>
      </div>
    </CalculatorSection>
  );
}

export const LtvCalculatorComponent = (props: any) => {
  const [input, setInput] = useState(defaultInput);
  const output = {
    ltv: getLtvValue(input)
  };
  // const { match } = props
  // const hideLink = match && match.params && parseInt(match.params.Id, 10) === 1;
  return (
    <div className="container-fluid">
      {/*<Header title={['Loan-to-value calculator']}/>*/}
      <Centered>
        {/*<HomeHeader />*/}
        <ClientDetails input={input} setInput={setInput} />
        <LevelsOfEnhancement input={input} output={output} />
      </Centered>
      {/*
      <Footer />
      <Disclaimer>
        These figures are illustrative only.
        The maximum amount of borrowing available will depend on individual
        circumstances and may be higher or lower than the figures displayed here.
        Although every effort is made to keep this information updated,
        loan-to-value (LTV) rates are subject to change.
      </Disclaimer>
      */}
    </div>
  );
};
